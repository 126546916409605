import * as React from "react";
import { Helmet } from "react-helmet";

import Theme from "../components/theme";

// import hero from '../images/coaching.jpeg';

import "../styles/index.css";
import Footer from "../components/footer";
import Hero from "../components/hero";
import CookieConsent from "../components/cookieConsent";
import Buchung from "../components/buchung";

const IndexPage = () => {
  return (
    <Theme>
      <Helmet>
        <title>Angela Hollstein - Systemisches Coaching</title>
        <meta
          name="keywords"
          content="coaching, freiburg, schweiz, deutschland, beratung, frauen, online"
        />
      </Helmet>
      {/* Hero */}
      <Hero
        title="Systemisches Coaching"
        heading="Coaching hinterfragt alte Wege und schafft Perspektiven"
        image="2022/cafe.jpg"
      />

      <div className="flex items-center justify-center">
        <div className="w-4/5 md:w-2/3 md:mt-20 z-10 leading-8 bg-white bg-opacity-70 rounded-lg p-10">
          Coaching blickt über den Tellerrand, hinterfragt bisherige Abläufe und
          Verhaltensmuster, um eine neue Qualität und höhere Zufriedenheit zu
          erreichen. Life und Business- Coaching hilft mit dynamischen Tools und
          Methoden, die eigenen Themen klarer zu sehen, neue Gedanken und
          Perspektiven zu erkennen, neuartige Impulse zu bekommen und ins aktive
          Handeln zu kommen.
          <br />
          Sie sind Experte Ihrer Inhalte und wissen was Sie wollen . Ich bin
          dagegen die Expertin der Struktur und als Coach immer auf Ihrer Seite
          . Transparent, wertschätzend und professionell.
          <br />
          Die Coaching-Sitzungen finden in meinen Beratungsräumen in der
          Freiburger Innenstadt statt oder sind online per Zoom möglich.
          <br />
          Oder machen Sie von meinem Angebot „Coaching by walking“ Gebrauch:
          Wenn man etwas verändern möchte, muss man sich bewegen. Coaching mal
          ganz anders. Bei einem 60-minütigen Spaziergang über den Tuniberg
          reden wir über Ihre eingebrachten Themen und versuchen
          Sinnzusammenhänge, Handlungsoptionen und Lösungswege zu erkunden.
          Dabei nutzen wir die Kraft von Bewegung und Natur, um neue Ideen
          entstehen zu lassen.
        </div>
      </div>

      <Buchung />

      <Footer />

      <CookieConsent />
    </Theme>
  );
};

export default IndexPage;
